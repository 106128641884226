import styled from "@emotion/styled";

const CoverWrapper = styled.div`
  z-index: -1;
  width: 100%;
  height: 497px;
  margin-top: -152px;
  position: relative;
`;

const CoverDimmer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
`;

const CoverColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: 291px;
  position: absolute;
`;

const CoverTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 10px;
`;

const CoverSubtitle = styled.h2`
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0;
`;

const CoverDivider = styled.hr`
  margin: 0;
  width: 100%;
  height: 1px;
  max-width: 375px;
  background-color: #f38f1d;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IntroParagraph = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
`;

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 276px;
  margin-bottom: 43px;
`;

const SectionTitle = styled.h3`
  font-family: Roboto;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 0;
  text-align: right;

  span {
    font-weight: 300;
  }
`;

const SectionBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: #f38f1d;
`;

const SectionParagraph = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  p:last-child {
    margin-bottom: 0;
  }
`;

const PDFParagraph = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  margin-top: 13px;
  margin-bottom: 131px;
`;

const CaseStudiesLink = styled.div`
  padding: 0 25px;
  min-height: 45px;
  border: solid 1px #f38f1d;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CaseStudiesText = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
`;

const GalleryImg = styled.img`
  width: 100%;
  height: 100%;
  min-height: 527px;
  object-fit: cover;
`;

const GallerySelectors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const styles = {
  CoverWrapper,
  CoverDimmer,
  CoverColumn,
  CoverTitle,
  CoverImage,
  CoverSubtitle,
  CoverDivider,
  IntroParagraph,
  SectionWrapper,
  SectionTitle,
  SectionBar,
  SectionParagraph,
  PDFParagraph,
  CaseStudiesLink,
  CaseStudiesText,
  GalleryImg,
  GallerySelectors
};

export default styles;
