import { Container, Row, Col, Visible } from "react-grid-system";
import React, { FunctionComponent, useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import SwipeableViews from "react-swipeable-views";
import { useIntl } from "react-intl";
import { graphql, navigate } from "gatsby";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

import { Layout } from "../../components";
import Preview from "../../components/common/Preview";

import styles from "./styles";

interface CaseStudyTemplateProps extends RouteComponentProps {
  data: {
    pdfIcon: { publicURL: string };
    prismicCaseStudy: {
      data: {
        pdf_title: { text: string };
        pdf_file: { url: string; raw: { name: string } };
        result_desc: { html: string };
        solution_desc: { html: string };
        client: string;
        client_desc: { html: string };
        challenge_desc: { html: string };
        case_description: string;
        case_intro: string;
        challenge_img: { alt: string; url: string };
        cover: { alt: string; url: string };
        client_image: { alt: string; url: string };
        gallery: Array<{ image: { alt: string; url: string } }>;
        solution_images: Array<{ solution: { alt: string; url: string } }>;
        seo_title?: string;
        seo_description?: string;
        seo_image?: { alt: string; url: string };
        canonical_url?: {
          url: string;
        };
      };
    };
  };
}

const CaseStudyTemplate: FunctionComponent<CaseStudyTemplateProps> = ({
  data
}) => {
  const { locale, formatMessage } = useIntl();
  const [currGalleryImg, setCurrGalleryImg] = useState<number>(0);

  const {
    CoverWrapper,
    CoverDimmer,
    CoverColumn,
    CoverImage,
    CoverTitle,
    CoverDivider,
    CoverSubtitle,
    IntroParagraph,
    SectionWrapper,
    SectionTitle,
    SectionBar,
    SectionParagraph,
    PDFParagraph,
    CaseStudiesLink,
    CaseStudiesText,
    GalleryImg,
    GallerySelectors
  } = styles;

  const handleClickSelector = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    direction: "left" | "right"
  ) => {
    e.preventDefault();

    if (direction === "left" && currGalleryImg > 0) {
      setCurrGalleryImg(currGalleryImg - 1);
    } else if (
      direction === "right" &&
      currGalleryImg < data.prismicCaseStudy.data.gallery.length - 1
    ) {
      setCurrGalleryImg(currGalleryImg + 1);
    }
  };

  const [client_desc, setContentClient] = useState("");
  const [challenge_desc, setContentChallenge] = useState("");
  const [solution_desc, setContentSolutions] = useState("");
  const [result_desc, setContentResult] = useState("");

  useEffect(() => {
    if (data.prismicCaseStudy.data) {
      setContentClient(data.prismicCaseStudy.data.client_desc.html);
      setContentChallenge(data.prismicCaseStudy.data.challenge_desc.html);
      setContentSolutions(data.prismicCaseStudy.data.solution_desc.html);
      setContentResult(data.prismicCaseStudy.data.result_desc.html);
    }
  }, [data]);

  return (
    <Layout
      whiteTopMenu
      topMenuOpacity={0}
      stickyTopMenu={false}
      metaTitle={data.prismicCaseStudy.data.seo_title}
      metaDescription={
        data.prismicCaseStudy.data.seo_description ||
        formatMessage({
          id: "pages.case-study.meta-description"
        })
      }
      metaKeywords={
        data.prismicCaseStudy.data.seo_keywords ||
        formatMessage({
          id: "pages.case-study.meta-keywords"
        })
      }
      metaImage={data.prismicCaseStudy.data.seo_image}
      metaURL={data.prismicCaseStudy.data.canonical_url?.url}
    >
      <CoverWrapper>
        <CoverDimmer />
        <CoverImage
          alt={data.prismicCaseStudy.data.cover?.alt}
          src={data.prismicCaseStudy.data.cover?.url}
        />
        <CoverColumn>
          <CoverTitle>{data.prismicCaseStudy.data.client}</CoverTitle>
          <CoverDivider />
          <CoverSubtitle>
            {data.prismicCaseStudy.data.case_description}
          </CoverSubtitle>
        </CoverColumn>
      </CoverWrapper>
      <Container>
        <Row style={{ marginTop: "100px", marginBottom: "100px" }}>
          <Col xs={12} md={10}>
            <IntroParagraph>
              {data.prismicCaseStudy.data.case_intro}
            </IntroParagraph>
          </Col>
        </Row>
      </Container>

      <SectionWrapper>
        <SectionTitle>
          <span>{formatMessage({ id: "the-client" }).split(" ")[0]}</span>
          {" " + formatMessage({ id: "the-client" }).split(" ")[1]}
        </SectionTitle>
        <SectionBar />
      </SectionWrapper>
      <Container style={{ marginBottom: "97px" }}>
        <Row>
          <Col xs={12} lg={6}>
            <SectionParagraph
              dangerouslySetInnerHTML={{
                __html: client_desc
              }}
            />
          </Col>
          {data.prismicCaseStudy.data.client_image?.url && (
            <Col xs={12} lg={6}>
              <img
                alt={data.prismicCaseStudy.data.client_image.alt}
                src={data.prismicCaseStudy.data.client_image.url}
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Col>
          )}
        </Row>
      </Container>

      <SectionWrapper>
        <SectionTitle>
          <span>{formatMessage({ id: "the-challenge" }).split(" ")[0]}</span>
          {" " + formatMessage({ id: "the-challenge" }).split(" ")[1]}
        </SectionTitle>
        <SectionBar />
      </SectionWrapper>
      <Container style={{ marginBottom: "97px" }}>
        <Row>
          <Col xs={12} md={10}>
            <SectionParagraph
              dangerouslySetInnerHTML={{
                __html: challenge_desc
              }}
            />
          </Col>
          {data.prismicCaseStudy.data.challenge_img?.url && (
            <Col xs={12} md={8} lg={6}>
              <img
                alt={data.prismicCaseStudy.data.challenge_img.alt}
                src={data.prismicCaseStudy.data.challenge_img.url}
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Col>
          )}
        </Row>
      </Container>

      <SectionWrapper>
        <SectionTitle>
          <span>{formatMessage({ id: "the-solution" }).split(" ")[0]}</span>
          {" " + formatMessage({ id: "the-solution" }).split(" ")[1]}
        </SectionTitle>
        <SectionBar />
      </SectionWrapper>
      <Container style={{ marginBottom: "97px" }}>
        <Row>
          <Col xs={12} md={10}>
            <SectionParagraph
              dangerouslySetInnerHTML={{
                __html: solution_desc
              }}
            />
          </Col>
          {data.prismicCaseStudy.data.solution_images[0]?.solution.url && (
            <Col xs={12} lg={8}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "527px",
                  objectFit: "cover"
                }}
                alt={
                  data.prismicCaseStudy.data.solution_images[0]?.solution.alt
                }
                src={
                  data.prismicCaseStudy.data.solution_images[0]?.solution.url
                }
              />
            </Col>
          )}
          {data.prismicCaseStudy.data.solution_images[1]?.solution.url && (
            <Col xs={12} lg={4}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "527px",
                  objectFit: "cover"
                }}
                alt={
                  data.prismicCaseStudy.data.solution_images[1]?.solution.alt
                }
                src={
                  data.prismicCaseStudy.data.solution_images[1]?.solution.url
                }
              />
            </Col>
          )}
        </Row>
      </Container>

      <SectionWrapper>
        <SectionTitle>
          <span>{formatMessage({ id: "the-result" }).split(" ")[0]}</span>
          {" " + formatMessage({ id: "the-result" }).split(" ")[1]}
        </SectionTitle>
        <SectionBar />
      </SectionWrapper>
      <Container style={{ marginBottom: "97px" }}>
        <Row>
          <Col xs={12} md={10}>
            <SectionParagraph
              dangerouslySetInnerHTML={{
                __html: result_desc
              }}
            />
          </Col>
          {data.prismicCaseStudy.data.gallery &&
            data.prismicCaseStudy.data.gallery.length > 0 &&
            data.prismicCaseStudy.data.gallery[0].image?.url && (
              <>
                <Visible md lg xl>
                  {data.prismicCaseStudy.data.gallery.map(
                    (galleryImg, index) => (
                      <Col
                        xs={12}
                        md={6}
                        lg={4}
                        key={`gallery#${index}`}
                        style={{ marginBottom: "25px" }}
                      >
                        <GalleryImg
                          alt={galleryImg.image.alt}
                          src={galleryImg.image.url}
                        />
                      </Col>
                    )
                  )}
                </Visible>
                <Visible xs sm>
                  <SwipeableViews
                    index={currGalleryImg}
                    onChangeIndex={index => setCurrGalleryImg(index)}
                  >
                    {data.prismicCaseStudy.data.gallery.map(
                      (galleryImg, index) => (
                        <GalleryImg
                          key={`gallery#${index}`}
                          alt={galleryImg.image.alt}
                          src={galleryImg.image.url}
                          style={{ marginBottom: 0 }}
                        />
                      )
                    )}
                  </SwipeableViews>
                  <GallerySelectors>
                    <a
                      style={{ cursor: "pointer", marginLeft: "50px" }}
                      onClick={e => handleClickSelector(e, "left")}
                    >
                      <FontAwesomeIcon
                        size="2x"
                        color="#f38f1d"
                        icon={faChevronLeft}
                      />
                    </a>
                    <a
                      style={{ cursor: "pointer", marginRight: "50px" }}
                      onClick={e => handleClickSelector(e, "right")}
                    >
                      <FontAwesomeIcon
                        size="2x"
                        color="#f38f1d"
                        icon={faChevronRight}
                      />
                    </a>
                  </GallerySelectors>
                </Visible>
              </>
            )}
        </Row>
      </Container>

      {data.prismicCaseStudy.data.pdf_file && (
        <Container style={{ marginBottom: "200px" }}>
          <Row>
            <Col
              xs={12}
              sm={8}
              md={4}
              offset={{ sm: 2, md: 4 }}
              style={{ textAlign: "center" }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data.prismicCaseStudy.data.pdf_file.url}
                style={{ textDecoration: "none" }}
              >
                <img
                  width="45"
                  height="56"
                  src={data.pdfIcon.publicURL}
                  alt={data.prismicCaseStudy.data.pdf_file.raw.name}
                />
                <PDFParagraph>
                  {data.prismicCaseStudy.data.pdf_title.text}
                </PDFParagraph>
              </a>
              <CaseStudiesLink
                style={{ textDecoration: "none" }}
                onClick={() => navigate(`/${locale}/#case-studies`)}
              >
                <CaseStudiesText>
                  {formatMessage({ id: "other-case-studies" })}
                </CaseStudiesText>
              </CaseStudiesLink>
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicCaseStudy(uid: { eq: $uid }, lang: { eq: $lang }) {
      data {
        pdf_title {
          text
        }
        pdf_file {
          url
          raw
        }
        result_desc {
          html
        }
        solution_desc {
          html
        }
        client
        client_desc {
          html
        }
        challenge_desc {
          html
        }
        case_description
        case_intro
        challenge_img {
          url
          alt
        }
        client_image {
          alt
          url
        }
        gallery {
          image {
            alt
            url
          }
        }
        cover {
          alt
          url
        }
        solution_images {
          solution {
            alt
            url
          }
        }
        seo_title
        seo_description
        seo_keywords
        seo_image {
          alt
          url
        }
        canonical_url {
          url
        }
      }
    }
    pdfIcon: file(relativePath: { eq: "icons/pdf-icon-orange.svg" }) {
      publicURL
    }
  }
`;

export default Preview(CaseStudyTemplate);
